import React from "react";

import { GoogleAuthProvider, signInWithPopup } from "@firebase/auth";
import { useAppContext, useFirebaseAuth } from "../helpers/context";
import { useSpring, animated } from "@react-spring/web";
import { db } from "../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { isAdmin } from "../helpers";
import PropTypes from "prop-types";

export const mapDomainToUid = async (domain) => {
  domain = domain.replace(/(^\w+:|^)\/\//, "");
  domain = domain.split("/")[0];

  const q = query(collection(db, "users"), where("domain", "==", domain));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs[0]?.data().uid;
};

SignIn.propTypes = {
  loading: PropTypes.bool,
};

export function SignIn() {
  const { auth } = useFirebaseAuth();
  const { setProfile, adminUid } = useAppContext();
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(async ({ user }) => {
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);
      console.log("docsnap", docSnap);
      const userSnapshot = { ...docSnap?.data() };
      console.log("usersnap", userSnapshot, typeof userSnapshot);
      console.log("user", user);
      // if (userSnapshot) {
      //   setProfile({
      //     user: userSnapshot,
      //     show: false,
      //   });
      // } else {
      // }
      // setProfile({
      //   user: {
      // role: userSnapshot.role,
      // uid: userSnapshot.uid,
      //   },
      //   show: false,
      // });
      const usr = {
        role: userSnapshot.role,
        uid: userSnapshot.uid,
      }
      console.log(usr)
      setProfile({
        show: false,
        user: usr
      })
      localStorage.setItem("user", JSON.stringify(userSnapshot));
      if (userSnapshot && isAdmin(userSnapshot)) {
        await updateDoc(userRef, {
          lastSeen: serverTimestamp().isEqual(new Date()) ? null : new Date(),
        });
        return;
      }
      if (docSnap.exists() && !isAdmin(user)) {
        console.log("not admin");
        const associations = userSnapshot.associations;
        const isAssociated = associations.includes(adminUid);
        if (!isAssociated) {
          associations.push(adminUid);
        }
        await updateDoc(userRef, {
          lastSeen: serverTimestamp().isEqual(new Date()) ? null : new Date(),
          associations,
        });
        return;
      }
      setDoc(userRef, {
        uid: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        lastSeen: serverTimestamp().isEqual(new Date()) ? null : new Date(),
        role: "user",
        associations: [adminUid],
      });
    });
  };

  const [logoProps] = useSpring(
    () => ({
      from: { opacity: 0, top: 0 },
      to: { opacity: 1, top: -50 },
    }),
    []
  );
  const [buttonProps] = useSpring(
    () => ({
      from: { opacity: 0, top: -50 },
      to: { opacity: 1, top: 0 },
      delay: 250,
    }),
    []
  );

  return (
    <div className="h-screen flex">
      <div className="flex flex-col w-full bg i justify-center items-center">
        <animated.div className="relative" style={logoProps}>
          <img
            src="/logo_light.png"
            alt="logo"
            className="w-auto max-w-[300px] mx-auto object-cover block relative -left-5"
          />
        </animated.div>
        <animated.div style={buttonProps} className="relative flex flex-col w-2/3 justify-center items-center">
          <button
            type="button"
            onClick={signInWithGoogle}
            className="text-white shadow-md hover:shadow-xl transition-shadow bg-[#0f2027] hover:bg-[#0f2027]/90 focus:ring-4 focus:ring-[#0f2027]/50 font-medium rounded-lg text-lg px-6 py-3.5 text-center inline-flex items-center dark:focus:ring-[#0f2027]/55 mr-2 mb-2">
            <svg
              className="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512">
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
            </svg>
            Sign in with Google
          </button>
        </animated.div>
      </div>
    </div>
  );
}
