import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebaseConfig";
import { createContext, useContext } from "react";

export const firebaseAuth = getAuth(firebaseApp);

export const FirebaseAuthContext = createContext(null);
export const AppContext = createContext(null);

export function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext);
  return context;
}
export function useAppContext() {
  const context = useContext(AppContext);
  return context;
}
