import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import defaultUser from "../assets/default.jpg";
import { FaRegTimesCircle } from "react-icons/fa";
import { useAppContext, useFirebaseAuth } from "../helpers/context";
import { useNavigate } from "react-router-dom";
import { VscSignOut } from "react-icons/vsc";
import { BiUserCircle } from "react-icons/bi";
import { signOut } from "firebase/auth";

export default function Profile() {
  const { setProfile, profile } = useAppContext();
  const containerRef = useRef(null);

  const user = profile.user;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target === containerRef.current) {
        setProfile({
          ...profile,
          show: false,
        });
      }
    };
    if (profile.show)
      document.addEventListener("click", handleClickOutside, true);
    else document.removeEventListener("click", handleClickOutside, true);
  }, [profile.show]);

  return (
    <div
      className={"profile-container text-center " + (profile.show && "visible")}
      ref={containerRef}
    >
      <div className="profile">
        <FaRegTimesCircle
          onClick={() =>
            setProfile({
              ...profile,
              show: false,
            })
          }
        />
        <img
          src={user?.photoURL || defaultUser}
          alt=""
          className="avatar !mx-auto !mb-4"
          onError={(e) => (e.target.src = defaultUser)}
        />
        <div className="name">{user?.displayName}</div>
        <div className="email">{user?.email}</div>
        <div className="lastSeen">
          Last signed{" "}
          <span className="lowercase">
            {user?.lastSeen?.seconds
              ? moment(user?.lastSeen?.seconds * 1000).calendar()
              : moment(user?.lastSeen).calendar()}
          </span>
        </div>
      </div>
    </div>
  );
}

// Dropdown showing profile and logout button
export function ProfileDropdown() {
  const navigate = useNavigate();
  const { loggedInUser, auth } = useFirebaseAuth();
  const { profile, setProfile } = useAppContext();
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
  }, []);
  return loggedInUser ? (
    <div
      className="dropdown relative cursor-pointer"
      onBlur={() => setShow(false)}
      ref={ref}
    >
      <div
        className={"profile-image rounded-full shadow-md" + (show && "show")}
        onClick={() => {
          setShow(!show);
        }}
      >
        <img
          src={loggedInUser.photoURL || defaultUser}
          alt="profile"
          className="relative top-[1px] avatar border-2 border-transparent"
          onError={(e) => (e.target.src = defaultUser)}
        />
      </div>
      <div className={"dropdown-menu " + (show && "show")}>
        <div
          className="dropdown-item flex items-center"
          onClick={() => {
            setProfile({
              ...profile,
              show: true,
            });
            setShow(false);
          }}
        >
          <BiUserCircle />
          Profile
        </div>
        <div
          className="dropdown-item flex items-center"
          onClick={() => {
            setShow(false);
            navigate("/");
            signOut(auth);
            setProfile({
              user: null,
              show: false,
            });
            localStorage.removeItem("user");
          }}
        >
          <VscSignOut />
          Sign Out
        </div>
      </div>
    </div>
  ) : null;
}
