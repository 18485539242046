import React from "react";
import { Link } from "react-router-dom";
import Profile, { ProfileDropdown } from "./Profile";

export default function Navbar() {
  return (
    <header className="bg-[#091318] h-[70px] text-white fixed w-full top-0 flex items-center justify-between z-50 p-[10px] shadow-lg">
      <Link to="/" className="text-2xl font-bold">
        <img
          src="/logo_light.png"
          alt="logo"
          className="h-[45px] w-auto object-cover block"
        />
      </Link>
      <ProfileDropdown />
      <Profile />
    </header>
  );
}
