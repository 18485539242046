import React from "react";
import moment from "moment";
import { useAppContext, useFirebaseAuth } from "../helpers/context";
import { MdContentCopy } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GoReply } from "react-icons/go";
import defaultUser from "../assets/default.jpg";
import PropTypes from "prop-types";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { scrollMessageIntoView } from "../helpers";

Message.propTypes = {
  message: PropTypes.object.isRequired,
  unsendMessage: PropTypes.func.isRequired,
  onMessageReply: PropTypes.func.isRequired,
  onLike: PropTypes.func.isRequired,
};

export default function Message({
  message,
  unsendMessage,
  onMessageReply,
  onLike,
}) {
  const { loggedInUser: user } = useFirebaseAuth();
  const { copiedSuccessfully, setSelectedUser } = useAppContext();
  const { text, uid, photoURL, createdAt, id } = message;

  const messageClass = uid === user.uid ? "sent" : "received";

  const handleClick = async () => {
    const user = await getDoc(doc(db, "users", uid));
    setSelectedUser({
      show: true,
      user: user.data(),
    });
  };

  return (
    <div className={`message-container ${messageClass}`} id={message.id}>
      {uid !== user.uid && (
        <img
          src={photoURL || defaultUser}
          className="avatar cursor-pointer"
          alt="profile"
          onError={(e) => (e.target.src = defaultUser)}
          onClick={handleClick}
        />
      )}
      <div className="flex flex-col">
        {message?.metadata?.replyingTo && (
          <div
            className={`reply-message ${messageClass} cursor-pointer`}
            onClick={() =>
              scrollMessageIntoView(message?.metadata?.replyingTo?.id)
            }
          >
            {message?.metadata?.replyingTo?.text}
          </div>
        )}
        <div
          className="message cursor-pointer select-none"
          onDoubleClick={() => {
            if (uid !== user.uid) {
              onLike(message);
            }
          }}
        >
          {text}
          <div>
            {createdAt && moment(createdAt.seconds * 1000).format("LT")}
          </div>
          {message?.metadata?.isLiked && (
            <span
              className="absolute -bottom-2 right-2 text-xs cursor-pointer"
              onClick={() => {
                onLike(message);
              }}
            >
              {messageClass === "received" ? "💙" : "🤍"}
            </span>
          )}
        </div>
      </div>
      <div className="mb-[12px] flex">
        <MdContentCopy
          data-tip="Copy"
          className="border-none outline-none text-sm md:text-base"
          onClick={copiedSuccessfully("copy", text)}
        />
        {uid === user.uid && (
          <>
            <RiDeleteBin5Line
              data-tip="Unsend"
              className="border-none outline-none text-sm md:text-base"
              onClick={() => {
                unsendMessage(id);
              }}
            />
          </>
        )}
        <GoReply
          data-tip="Reply"
          className="border-none outline-none text-sm md:text-base"
          onClick={() => {
            onMessageReply(message);
          }}
        />
      </div>
    </div>
  );
}
