/* eslint-disable no-undef */
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getFirestore } from "@firebase/firestore";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

export const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestToken = async (setClientToken, retryNotification) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_vapidKey,
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      setClientToken(currentToken);
      toast.success("You are now subscribed to notifications. 🎉");
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      toast.error("You are not subscribed to notifications. 😔");
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        requestToken(setClientToken, retryNotification);
      } else {
        console.log("Unable to get permission to notify.");
        toast.error("Unable to get permission to notify. 😔");
      }
      setClientToken(null);
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    retryNotification("An error occurred while retrieving token. ", () => {
      requestToken(setClientToken, retryNotification);
    });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const db = getFirestore(firebaseApp);
