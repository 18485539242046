import React, { useEffect, useRef, useState } from "react";
import { useFirebaseAuth, useAppContext } from "../../helpers/context";
import { db } from "../../firebaseConfig";
import {
  addDoc,
  collection,
  orderBy,
  query,
  serverTimestamp,
  deleteDoc,
  updateDoc,
  doc,
  where,
} from "@firebase/firestore";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import ReactTooltip from "react-tooltip";
import { FiSend } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import Message from "../Message";
import { useParams } from "react-router-dom";
import { scrollMessageIntoView, sendNotification } from "../../helpers";
import moment from "moment";

export default function ChatRoomAdmin() {
  const { id: userId } = useParams();
  const { loggedInUser } = useFirebaseAuth();
  const { unsentSuccessfully } = useAppContext();
  const [formValue, setFormValue] = useState("");
  const messageBoxRef = useRef();

  // * get snapshot of the user (for accessing clientToken used by FCM)
  const [userSnapshot] = useDocument(doc(db, "users", userId));

  // * get all messages between admin and user.
  const messagesRef = collection(db, "messages");
  const orderedMessages = query(
    messagesRef,
    where("to", "in", [loggedInUser?.uid ?? "", userId]),
    where("uid", "in", [loggedInUser?.uid ?? "", userId]),
    orderBy("createdAt")
  );
  const [messagesSnapshot, loading] = useCollection(orderedMessages);
  const messages = [];
  messagesSnapshot?.docs.forEach((message) => {
    messages.push({
      id: message.id,
      ...message.data(),
    });
  });

  const previousMessageLengthRef = useRef(messages.length);
  // * scroll to bottom when new message is added.
  useEffect(() => {
    if (messages.length !== previousMessageLengthRef.current) {
      scrollMessageIntoView(messages[messages.length - 1].id);
    }
    previousMessageLengthRef.current = messages.length;
  }, [messagesSnapshot]);

  const sendMessage = async (e) => {
    e.preventDefault();
    const { uid, photoURL } = loggedInUser;
    const message = formValue;
    setFormValue("");
    const messageBody = {
      text: message.trim(),
      createdAt: serverTimestamp(),
      uid,
      to: userId,
      photoURL,
    };
    if (replyingTo) {
      messageBody.metadata = {
        replyingTo: {
          id: replyingTo.id,
          text: replyingTo.text,
          uid: replyingTo.uid,
          to: replyingTo.to,
          photoURL: replyingTo.photoURL,
        },
        isLiked: false,
      };
      setReplyingTo(null);
    }
    await addDoc(messagesRef, messageBody);
    messageBoxRef.current?.focus();
    if (userSnapshot?.data()?.clientTokens)
      userSnapshot?.data()?.clientTokens?.forEach(async (token) => {
        await sendNotification(token, loggedInUser, message);
      });
  };

  const unsendMessage = async (id) => {
    const message = doc(db, "messages", id);
    await deleteDoc(message);
    unsentSuccessfully();
    localStorage.setItem("totalMessages", messages.length);
  };

  const [replyingTo, setReplyingTo] = useState(null);
  const onMessageReply = (message) => {
    setReplyingTo(message);
    messageBoxRef.current?.focus();
  };

  const likeMessage = async (message) => {
    const messageRef = doc(db, "messages", message.id);
    await updateDoc(messageRef, {
      metadata: {
        ...message.metadata,
        isLiked: !message?.metadata?.isLiked ?? true,
      },
    });
  };

  return (
    <>
      <main className={`chatArea ${replyingTo ? "replyingTo" : ""}`}>
        {loading ? (
          <div className="flex justify-center mt-5">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <ReactTooltip />
            {messages?.map((msg, index) => {
              const currentMessageTime = new Date(
                msg?.createdAt?.seconds * 1000
              );
              const previousMessageTime =
                index > 0
                  ? new Date(messages[index - 1]?.createdAt?.seconds * 1000)
                  : null;
              const isSameDay =
                previousMessageTime &&
                currentMessageTime.getDate() === previousMessageTime.getDate();
              return (
                msg !== undefined && (
                  <div key={msg?.id}>
                    {!isSameDay && (
                      <div className="text-center mb-4 text-gray-100 opacity-20 text-sm">
                        {currentMessageTime.toDateString() !== "Invalid Date" &&
                          moment(currentMessageTime).calendar()}
                      </div>
                    )}
                    <Message
                      message={msg}
                      unsendMessage={unsendMessage}
                      onMessageReply={onMessageReply}
                      onLike={likeMessage}
                    />
                  </div>
                )
              );
            })}
          </>
        )}
      </main>

      <form className="form" onSubmit={sendMessage}>
        {replyingTo && (
          <div className="absolute -top-[50px] h-[50px] w-full flex items-center justify-between bg-gray-900 bg-opacity-80 text-base text-white px-4 py-2 border-l-4 border-gray-600">
            {replyingTo.text}
            <AiOutlineClose
              className="cursor-pointer"
              onClick={() => setReplyingTo(null)}
            />
          </div>
        )}
        <input
          className="leading-normal w-full text-2xl bg-gray-900 text-white px-[15px] outline-none border-none z-10"
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Message..."
          autoComplete={"off"}
          ref={messageBoxRef}
        />
        <button type="submit" disabled={!formValue} className="sendButton">
          <FiSend />
        </button>
      </form>
    </>
  );
}
