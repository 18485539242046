import React from "react";
import { collection, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useCollection } from "react-firebase-hooks/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useFirebaseAuth } from "../../helpers/context";
import defaultUser from "../../assets/default.jpg";

/*
const orderedMessages = query(
    messagesRef,
    where("to", "in", [loggedInUser?.uid ?? ""]),
    orderBy("createdAt")
  );
  const [messagesSnapshot] = useCollection(orderedMessages);
  const messagesReceived = [];
  messagesSnapshot?.docs.forEach((message) => {
    messagesReceived.push({
      id: message.id,
      ...message.data(),
    });
  });
*/
const useLatestMessages = () => {
  const { loggedInUser } = useFirebaseAuth();
  const messagesRef = collection(db, "messages");
  const orderedMessagesReceived = query(
    messagesRef,
    where("to", "in", [loggedInUser?.uid ?? ""]),
    orderBy("createdAt")
  );
  const [messagesSnapshotReceived] = useCollection(orderedMessagesReceived);
  const messagesReceived = [];
  messagesSnapshotReceived?.docs.forEach((message) => {
    messagesReceived.push({
      id: message.id,
      ...message.data(),
    });
  });

  const orderedMessagesSent = query(
    messagesRef,
    where("uid", "in", [loggedInUser?.uid ?? ""]),
    orderBy("createdAt")
  );
  const [messagesSnapshotSent] = useCollection(orderedMessagesSent);
  const messagesSent = [];
  messagesSnapshotSent?.docs.forEach((message) => {
    messagesSent.push({
      id: message.id,
      ...message.data(),
    });
  });

  return messagesReceived.concat(messagesSent);
};

export default function Admin() {
  const navigate = useNavigate();
  const { loggedInUser } = useFirebaseAuth();

  const [usersSnapshot, loading] = useCollection(
    query(
      collection(db, "users"),
      orderBy("lastSeen", "desc"),
      where("associations", "array-contains", loggedInUser?.uid || "")
    )
  );
  const users = [];
  usersSnapshot?.docs?.forEach((user) => {
    users.push({
      id: user.id,
      ...user.data(),
    });
  });

  const messages = useLatestMessages();
  const usersWithLastMessage = [];
  users.forEach((user) => {
    const lastMessage = messages
      .filter((message) => message.uid === user.uid || message.to === user.uid)
      .sort((a, b) => b.createdAt - a.createdAt)[0];
    usersWithLastMessage.push({
      ...user,
      lastMessage,
    });
  });

  usersWithLastMessage.sort((a, b) => b?.lastMessage?.createdAt - a?.lastMessage?.createdAt)

  if (loading) {
    return (
      <div className="flex justify-center flex-col gap-[12px] relative top-5">
        {[1, 2, 3, 4, 5].map((i) => (
          <Skeleton key={i} />
        ))}
      </div>
    );
  }

  return (
    <div className="admin relative top-5">
      <div className="flex flex-col gap-4 items-center justify-center mx-auto max-w-[95%] sm:max-w-[920px] w-full px-2 pb-8">
        {usersWithLastMessage?.map((user) => (
          <div
            className="relative flex items-center w-full px-[12px] md:px-[20px] py-[10px] gap-[10px] cursor-pointer bg-[#2c5364] hover:bg-[#2c5364] hover:shadow-xl hover:scale-105 transition duration-200 ease-in-out shadow-lg rounded-xl  mx-auto"
            key={user.id}
            onClick={() => {
              navigate(user.uid);
            }}
          >
            <img
              src={user.photoURL}
              alt={user.displayName}
              className="avatar"
              onError={(e) => (e.target.src = defaultUser)}
            />
            <div className="w-full flex justify-between items-center text-white">
              <div className="flex flex-col gap-0.5 max-w-[180px]">
                <p className="truncate text-base">{user.displayName}</p>
                <p className="truncate text-gray-400 text-sm">
                  {user?.uid !== user.lastMessage?.uid && "You: "} {user.lastMessage?.text}
                </p>
              </div>
              <p className="opacity-50 mt-[5px] leading-[1px] text-xs">
                <MessageDate createdAt={user?.lastMessage?.createdAt} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const Skeleton = () => {
  return (
    <div className="relative flex w-full px-[20px] py-[10px] gap-[10px] cursor-pointer bg-[#2c5364] hover:bg-[#2c5364] hover:shadow-xl transition duration-200 ease-in-out shadow-lg rounded-xl max-w-[920px] mx-auto animate-pulse">
      <div className="skeleton h-[40px] w-[40px] rounded-full bg-gray-300 bg-opacity-50"></div>
      <div className="w-full flex justify-between items-center text-white">
        <div className="w-full flex flex-col gap-2">
          <p className="truncate w-1/2 h-[14px] bg-gray-300 bg-opacity-50 rounded-sm"></p>
          <p className="truncate w-1/3 h-[8px] bg-gray-300 bg-opacity-50 rounded-sm"></p>
        </div>
        <p className="lastSeen w-[100px] bg-gray-200 bg-opacity-50 h-[10px] rounded-sm"></p>
      </div>
    </div>
  );
};


// eslint-disable-next-line react/prop-types
const MessageDate = ({ createdAt }) => {
  const now = moment();
  // eslint-disable-next-line react/prop-types
  const messageDate = moment(createdAt?.toDate());

  let formattedDate;

  if (!createdAt) {
    formattedDate = "Invalid date";
  } else if (messageDate.isSame(now, 'day')) {
    formattedDate = messageDate.format('LT');
  } else if (messageDate.isSame(now.subtract(1, 'day'), 'day')) {
    formattedDate = "Yesterday";
  } else if (messageDate.isAfter(now.subtract(6, 'days'))) {
    formattedDate = messageDate.format('dddd');
  } else {
    formattedDate = messageDate.format('MM/DD/YY');
  }

  return <span>{formattedDate}</span>;
};