import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import "./App.css";
import ChatRoomAdmin from "./components/admin/ChatRoomAdmin";
import { SignIn, mapDomainToUid } from "./components/Auth";

import { ToastContainer, toast } from "react-toastify";
import Admin from "./components/admin";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, requestToken } from "./firebaseConfig";
import BgAnimation from "./components/BgAnimation";
import Navbar from "./components/Navbar";
import { AppContext, FirebaseAuthContext, firebaseAuth } from "./helpers/context";
import ChatRoomUser from "./components/ChatRoomUser";
import { AiOutlineClose } from "react-icons/ai";

export default function App() {
  const [loggedInUser, loadingUser] = useAuthState(firebaseAuth);
  const [adminUid, setAdminUid] = useState("");
  const [profile, setProfile] = useState({
    show: false,
    user: null,
  });
  const [selectedUser, setSelectedUser] = useState({
    show: false,
    user: null,
  });

  const copiedSuccessfully = (type, text) => () => {
    navigator.clipboard.writeText(text);
    toast.success("Text copied to clipboard.");
  };

  const unsentSuccessfully = () => {
    toast.success("Message has been unsent.");
  };

  const retryNotification = (message, handleRetry) => {
    toast(
      <div>
        <h3>
          <span className="max-w-[90%] block">{message}</span>
          <button onClick={handleRetry} className="absolute top-3 right-3 text-sm">
            <AiOutlineClose />
          </button>
        </h3>
      </div>,
      {
        type: "error",
        onClose: () => console.log("closed"),
      }
    );
  };

  const [clientToken, setClientToken] = useState("");

  useEffect(() => {
    (async () => {
      await Notification.requestPermission();
      requestToken(setClientToken, retryNotification);

      const adminId = await mapDomainToUid(window.location.href);
      setAdminUid(adminId || "");

      const cachedUser = JSON.parse(localStorage.getItem("user"));
      if (cachedUser) {
        const userRef = doc(db, "users", cachedUser.uid);
        await updateDoc(userRef, {
          lastSeen: new Date(),
        });
        cachedUser.lastSeen = new Date();
        setProfile({ ...profile, user: cachedUser });
        localStorage.setItem("user", JSON.stringify(cachedUser));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (loggedInUser && clientToken) {
        const userRef = doc(db, "users", loggedInUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && !userDoc.data()?.clientTokens?.includes(clientToken)) {
          let tokens = userDoc.data()?.clientTokens || [];
          tokens.push(clientToken);
          await updateDoc(userRef, {
            clientTokens: tokens,
          });
        }
      }
    })();
  }, [clientToken, loggedInUser]);

  return (
    <Router>
      <FirebaseAuthContext.Provider value={{ auth: firebaseAuth, loggedInUser, loadingUser }}>
        <AppContext.Provider
          value={{
            profile,
            adminUid,
            selectedUser,
            setSelectedUser,
            setAdminUid,
            setProfile,
            copiedSuccessfully,
            unsentSuccessfully,
          }}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            toastStyle={{ backgroundColor: "#2c5364", color: "white" }}
            closeButton={false}
          />
          <div className="App mx-auto">
            <BgAnimation />
            {loggedInUser && <Navbar />}
            <section className="mt-[70px]">
              <Routes>
                <Route
                  path="/"
                  exact
                  element={
                    <>
                      {loggedInUser ? (
                        <>
                          {profile?.user?.role === "admin" && <Admin />}
                          {profile?.user?.role === "user" && <ChatRoomUser />}
                        </>
                      ) : (
                        <SignIn loading={loadingUser} />
                      )}
                    </>
                  }
                />
                <Route path="/:id" exact element={<ChatRoomAdmin />} />
              </Routes>
            </section>
          </div>
        </AppContext.Provider>
      </FirebaseAuthContext.Provider>
    </Router>
  );
}
