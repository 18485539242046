import axios from "axios";

export const isAdmin = (user) => {
  return user && user.role === "admin";
};

export const sendNotification = async (token, auth, body) => {
  await axios.post(
    "https://fcm.googleapis.com/fcm/send",
    {
      to: token,
      notification: {
        title: `New Message from ${auth.displayName || auth.email}`,
        body,
        actions: [
          {
            action: "reply",
            title: "Reply",
          },
        ],
        click_action: window.location.href.split("/")[0],
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        // eslint-disable-next-line no-undef
        Authorization: `key=${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
      },
    }
  );
};

export const scrollMessageIntoView = (id) => {
  const el = document.getElementById(id);
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  } else {
    window.scrollTo(0, document.body.scrollHeight);
  }
};
// // eslint-disable-next-line no-undef
// const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
// export const decryptData = (msg) => {
//   try {
//     const bytes = CryptoJS.AES.decrypt(msg, CRYPTO_KEY);
//     if (bytes.sigBytes < 0) return msg;
//     const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     return data;
//   } catch (error) {
//     return msg;
//   }
// };

// export const encryptData = (msg) => {
//   const data = CryptoJS.AES.encrypt(JSON.stringify(msg), CRYPTO_KEY).toString();
//   return data;
// };
